body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* index.css */

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: sans-serif;
  background-color: #f7f7f7;
}

.container h1 {
  justify-self: center;
}

.container p {
  justify-self: center;
}

form {
  display: grid;
  row-gap: 30px;
}

input {
  width: 100%;
  box-sizing: border-box;
  border: solid 1px #ddd;
  padding: 10px;
  font-size: 16px;
  outline: none;
  appearance: none;
  border-radius: 0;
}

button {
  justify-self: center;
  border: none;
  background-color: #0d6efd;
  color: #fff;
  padding: 10px;
  font-size: 16px;
}

button:hover {
  cursor: pointer;
  opacity: 0.7;
}

@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");

.logo {
  padding: 0px 20px !important;
}

.hero-text {
  font-size: 0.8em;
}

.input-field {
  width: 70%;
  height: 50px !important;
  padding: 0px !important;
  position: absolute;
  /* margin-left:15%!important; */
  margin: 10px 18% !important;
  background: #f1f3f4;
}

input[type="note"] {
  background-color: transparent;
  border: none;
  border-radius: 0;
  outline: none;
  height: 3rem;
  width: 100%;
  font-size: 1.4em;
  margin: 0 0 8px 0;
  padding: 0;
  border-bottom: 0px !important;
}

.text-justify {
  text-align: justify;
}

.search-icon {
  height: 100%;
  font-size: 2em !important;
}

.close-icon {
  height: 100%;
  margin-top: -5px !important;
  font-size: 1.5em !important;
}

.label-icon {
  margin-top: -5px !important;
}

.row {
  width: 90%;
  min-height: calc(90vh - 74px);
}

.newnote {
  height: auto !important;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 50%;
}

.materialize-textarea {
  font-size: 1.2em !important;
  border-bottom: 0px !important;
  transition: none !important;
  box-shadow: none !important;
}

.hero-icon {
  font-size: 1.25em;
}

nav {
  font-family: "Poppins";
  min-height: 70px;
}

nav .brand-logo i,
nav .brand-logo [class^="mdi-"],
nav .brand-logo [class*="mdi-"],
nav .brand-logo i.material-icons {
  font-size: 1.25em;
}

.card {
  transition: all 1s !important;
}
.card-date {
  font-size: 10px;
  color: #999;
}
.card .card-content .card-title {
  font-size: 18px;
}
.card .card-content {
  padding: 12px 24px;
  transition: all 1s !important;
}

.input-title {
  transition: all ease-in-out 1s !important;
}

.page-footer {
  position: relative;
  bottom: 0;
  width: 100%;
}

/* CUSTOM SCROLLBAR */

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2196f3;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #64b5f6;
}

@media only screen and (max-width: 768px) {
  .newnote {
    width: 84%;
  }

  .m3 {
    width: 50% !important;
    min-width: 50% !important;
  }
}

@media only screen and (max-width: 1072px) {
  .m3 {
    min-width: 33.333333% !important;
  }
}

@media only screen and (max-width: 600px) {
  .newnote {
    width: 84%;
  }

  .m3 {
    width: 100% !important;
    min-width: 100% !important;
  }
}

.btn-floating.btn-large.halfway-fab {
  bottom: -50px;
}
